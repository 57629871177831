<template>
  <div class="ant-register">
    <!-- 头部 -->
    <!-- <mayi-header class="header" /> -->
    <!-- 内容 -->
    <router-view class="content" />
    <!-- 底部 -->
    <!-- <mayi-footer class="footer" /> -->
  </div>
</template>
<script>
// import header from "@/components/login/header";
// import footer from "@/components/login/footer";
export default {
  components: {
    // "mayi-header": header,
    // "mayi-footer": footer,
  },
  data() {
    return {};
  }
};
</script>
<style rel='stylesheet/scss' lang='scss' scoped>
.ant-register {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f5f6f7;
}
</style>
